<template>
  <h2> super admin  Live Class</h2>
</template>

<script>
export default {

}
</script>

<style>

</style>