<template>
  <div>
    <div class="flex align-center">
       <div class="flex justify-between align-center mb-6">
         <h2 class="text-uppercase font-bold">Create Dummy Demo Coching Center</h2>
      </div>
      <div class="ml-auto bg-green pointer px-2 py-1 text-white rounded inline-flex align-center">
          <svg class="w-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 32 32" fill="#5B5B5B">
            <path
                d="M16 4C9.382813 4 4 9.382813 4 16C4 22.617188 9.382813 28 16 28C22.617188 28 28 22.617188 28 16C28 9.382813 22.617188 4 16 4 Z M 16 6C21.535156 6 26 10.464844 26 16C26 21.535156 21.535156 26 16 26C10.464844 26 6 21.535156 6 16C6 10.464844 10.464844 6 16 6 Z M 16 10C13.800781 10 12 11.800781 12 14L14 14C14 12.882813 14.882813 12 16 12C17.117188 12 18 12.882813 18 14C18 14.765625 17.507813 15.445313 16.78125 15.6875L16.375 15.8125C15.558594 16.082031 15 16.863281 15 17.71875L15 19L17 19L17 17.71875L17.40625 17.59375C18.945313 17.082031 20 15.621094 20 14C20 11.800781 18.199219 10 16 10 Z M 15 20L15 22L17 22L17 20Z"
                fill="#fff"/>
          </svg>
          <span class="text-base ml-1"> Help </span>
        </div>
      </div>
      <div class="md-layout md-gutter mt-6">
        <div class="md-layout-item md-size-100">
          <form @submit.prevent="onGenerateDummyCochingCenter" class="md-layout-item md-size-50 md-xsmall-size-100 dummy-coching-center-form">
                
                <InputFieldComponent
                  type="text"
                  label="Cocaching Center Name"
                  class="my-4 align-left"
                  v-model.trim="$v.name.$model"
                  :message="!$v.name.required && $v.name.$dirty ? 'Field is required' : null"
                />
                <InputFieldComponent
                  label="Email"
                  class="my-4 align-left"
                  v-model.trim="$v.email.$model"
                  :message="hasEmailError"
                  />
                
                  <InputFieldComponent
                  label="Subdomain"
                  class="my-4 align-left"
                  v-model.trim="$v.subdomain.$model"
                  :message="hasSubdomainError"
                  />
                <div class="action-buttons">
                  <md-button class="bg-danger text-white rounded" :to="{ name: 'master.dummyCoching' }" > Back </md-button>
                  <!-- <md-button type="submit" class="bg-victoria text-white rounded" :disabled="disabled">Submit</md-button> -->
                  <Button
                    :disabled="disabled"
                    :loading="loading"
                    class="bg-victoria text-white rounded"
                    label="Submit"
                    type="submit"
                >
                </Button>
                </div>
          </form>
        </div>
     </div>
  </div>
</template>

<script>
import { InputFieldComponent, Button } from "@/components";
import { required } from "vuelidate/lib/validators";
import { email } from "vuelidate/lib/validators";
import {mapActions, mapMutations} from 'vuex';
export default {
components: {
    InputFieldComponent,
    Button
  },  

data: () => ({
    disabled: false,
    loading: false,
    email: null,
    name: null,
    subdomain: null,
    isSubdomainAvailable: true,
    isEmailAvailable: true,
  }),

  computed: {
    hasSubdomainError() {
      
      if ((!this.$v.subdomain.required && this.$v.subdomain.$dirty || !this.isSubdomainAvailable)) {
        if(!this.isSubdomainAvailable) {
          return 'Not available is subdomain'
        }
        return !this.$v.subdomain.required && this.$v.subdomain.$dirty ? 'Field is required' : !this.$v.subdomain.email && this.$v.subdomain.$dirty ? 'Should be in subdomain format' : null;
      }
      return null;
    },
    hasEmailError() {
      
      if ((!this.$v.email.required && this.$v.email.$dirty || !this.$v.email.email && this.$v.email.$dirty) || !this.isEmailAvailable) {
        if(!this.isEmailAvailable) {
          return 'Email not available'
        }
        return !this.$v.email.required && this.$v.email.$dirty ? 'Field is required' : !this.$v.email.email && this.$v.email.$dirty ? 'Should be in email format' : null;
      }
      return null;
    },
  },

watch: {
      subdomain(after) {
        this.fetch('subdomain', after);
      },
      email(after) {
        this.fetch('email', after);
      }
  },
  methods: {

    ...mapMutations({
      setShowSnackbar: "setShowSnackbar",
    }),
    ...mapActions({
      actOrganizationSave: "dummyOrganization/actOrganizationSave",
      actCheckAvailableSubdomainOrEmail: "dummyOrganization/actCheckAvailableSubdomainOrEmail",
    }),

    fetch(key, value) {

       this.actCheckAvailableSubdomainOrEmail({ [key] : value}).then((response) => {
       if(key == 'subdomain'){
          this.isSubdomainAvailable = response;
       }else{
         this.isEmailAvailable = response;
       }
        
       
       });
    },
    
    onGenerateDummyCochingCenter() {
     
      this.disabled = true;
      this.loading = true;
      this.$v.$touch();
      if(this.$v.$invalid || !this.isEmailAvailable  || !this.isSubdomainAvailable ) {
        this.loading = false;
        this.disabled = false;
        return;
      }

      var form = {name: this.name, email: this.email, subdomain: this.subdomain}

      this.actOrganizationSave(form)
      .then((response) => {
        
          this.setShowSnackbar(response.message);
          setTimeout(() => {
            this.disabled = false;
            this.loading = false;
            this.$router.push({name: `master.dummyCoching`})
         }, 1000);
          
      })
      .catch(() => {
        this.disabled = false;
        this.loading = false;
        this.setShowSnackbar('Failed, Please try again');
      });
    }
  }, 

  validations: {
     
      name: {required},
      email: {required, email},
      subdomain: {required}
    }
  
}
</script>

<style>

  .dummy-coching-center-form{
      margin-left: auto;
      margin-right: auto;
  }

  .action-buttons{
    margin-left: 36%;
  }

</style>