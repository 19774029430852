<template>
  <div>
    <div class="flex justify-center">
      <PageTopNavigation icon-type="svg" :items="items" item-width="w-32" />
    </div>


    <Tab :items="tabs" class="my-10" >
      <template v-slot:icon="{ item }">
        <span v-html="item.icon"></span>
      </template>
    </Tab>

    <router-view />

  </div>
</template>

<script>
import PageTopNavigation from "@/components/atom/PageTopNavigation";
import data from "@/data/master/qatm/data";
import Tab from "@/components/atom/Tab";
export default {
  name: "People-Management-Index-View",
  components: {
    PageTopNavigation,
    Tab,
  },
  data() {
    return {
      items: data.topNavigation,
      tabs: data.peopleManagementTab
    };
  },
};
</script>