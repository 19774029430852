<template>
  <h2>Super admin:  Study plan </h2>
</template>

<script>
export default {

}
</script>

<style>

</style>